@use 'sass:math';


@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue.eot');
  src: url('./assets/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/HelveticaNeue.woff2') format('woff2'),
      url('./assets/fonts/HelveticaNeue.woff') format('woff'),
      url('./assets/fonts/HelveticaNeue.ttf') format('truetype'),
      url('./assets/fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Bold.eot');
  src: url('./assets/fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
      url('./assets/fonts/HelveticaNeue-Bold.woff') format('woff'),
      url('./assets/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
      url('./assets/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Medium.eot');
  src: url('./assets/fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
      url('./assets/fonts/HelveticaNeue-Medium.woff') format('woff'),
      url('./assets/fonts/HelveticaNeue-Medium.ttf') format('truetype'),
      url('./assets/fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$bg-color: (
  sweet: #009ee5,
  funky: #08c872,
  spicy: #e5e3a9,
  sour: #191c21,
  minty: #8e6fda,
  pink: #f6b8cf,
);

$text-color: (
  sweet: #f9c5d2,
  funky: #fef9b9,
  spicy: #8f6fda,
  sour: #ed1c24,
  minty: #fb9e00,
  pink: #19a25a,
);

$square-size: (
  portrait: 8,
  landscape: 19,
);

@mixin breakpoint ($breakpoint) {

  @if $breakpoint == landscape {
    @media only screen and (min-aspect-ratio: 1/1) {
      @content;
    }
  }

  @if $breakpoint == portrait {
    @media only screen and (max-aspect-ratio: 1/1) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (min-width: 480px) {
      @content;
    }
  }
}

@function square($breakpoint, $size) {
  $max: map-get($square-size, $breakpoint);
  @return calc((var(--square-size) * #{$size}) + 0px);
}

body {
  transition:
    color 250ms linear,
    background-color 250ms linear;

  @each $name, $value in $bg-color {
    &.#{$name} {
      --swiper-navigation-color: $value !important;
      background-color: $value !important;

      .bg-filler::after {
        background-color: $value !important;
      }
    }
  }

  @each $name, $value in $text-color {
    &.#{$name} {
      color: $value !important;

      .bg-filler {
        // box-shadow: inset 0 0 0 1px $value !important;
      }

      .svg-stroke {
        stroke: $value !important;
      }
    }
  }
}


a,
a:link,
a:visited {
  color: inherit;
}

.bg-filler {
  padding: 1rem;
  position: relative;
  z-index: 1;
  transition: color 250ms linear;

  &::after {
    transition: background-color 250ms linear;
    content: '';
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 1px;
    right: 1px;
    z-index: -1;
  }
}

.grid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.instagram {
  display: block;
  margin: 0 auto;
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-miterlimit: 10;
  transition: stroke 250ms linear;
  width: square(landscape, 2);
  height: square(landscape, 2);

  @include breakpoint(landscape) {
    width: square(landscape, 1);
    height: square(landscape, 1);
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.slider-fullpage {
  width: 100%;
  height: square(portrait, 9);

  &--product {
    width: square(portrait, 5.25);
    margin: square(portrait, 2) auto;
    user-select: none;

    img {
      object-fit: cover;
      max-width: 100%;
    }
  }

  @include breakpoint(landscape) {
    &--product {
      width: square(landscape, 4);
      float: right;
      margin:
        square(landscape, 2)
        square(landscape, 2)
        0
        0;

      img {
        object-fit: cover;
        max-width: 100%;
      }
    }
  }
}

.super-header {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: square(portrait, 8);
  height: square(portrait, 5);
  overflow: hidden;
  line-height: 0.9;

  &--text {
    text-align: center;
    font-size: 20.5vw;
    font-weight: bold;
    display: block;
    transform: translateX(100vw);
    transition: transform ease-out 550ms;
  }

  &--inverted {
    transform: rotate(180deg) translateX(100vw);
  }

  &--active {
    .super-header--text {
      transform: translateX(0);
    }

    .super-header--inverted {
      transform: rotate(180deg) translateX(0);
    }
  }

  @include breakpoint(landscape) {
    transform: rotate(90deg) translateY(-100%);

    position: fixed;
    left: 0;
    top: 0;
    width: 100vh;
    height: square(landscape, 6);
    transform-origin: top left;

    &--text {
      font-size: 12vw;
    }
  }
}

.super-text {
  text-align: center;
  width: square(portrait, 6);
  height: square(portrait, 2);
  align-items: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  margin-top: square(portrait, 1);
  margin-left: square(portrait, 1);

  h2 {
    margin: 0;
  }

  @include breakpoint(landscape) {
    text-align: left;
    position: absolute;
    z-index: 1;
    margin: 0;
    width: square(landscape, 5);
    height: square(landscape, 2);
    top: square(landscape, 1);
    left: square(landscape, 7);
    font-size: 1.3vw;
    line-height: 1.5;
  }
}

.super-info {
  text-align: center;
  padding-bottom: square(portrait, 2);

  > p {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-left: square(portrait, 1);
    display: flex;
    vertical-align: middle;
    width: square(portrait, 6);
    height: square(portrait, 1);
  }

  > a {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-left: square(portrait, 3);
    display: flex;
    vertical-align: middle;
    width: square(portrait, 2);
    height: square(portrait, 2);
  }

  @include breakpoint(landscape) {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    position: absolute;
    top: square(landscape, 5);
    left: square(landscape, 7);
    padding-bottom: 0;

    > p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: square(landscape, 4);
      height: square(landscape, 1);
      order: 2;
      font-size: 1.5rem;

      &:first-child {
        width: square(landscape, 4);
        height: square(landscape, 1);
      }
    }

    > a {
      margin: 0;
      order: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: square(landscape, 1);
      height: square(landscape, 1);
    }
  }
}

.swiper-button-prev {
  @include breakpoint(landscape) {
    left: square(landscape, 11.3);
  }
}