*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body *{
  max-width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
  min-height: 100vh;
}
